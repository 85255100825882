import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                            from 'axios';
import { useHistory }                   from 'react-router-dom';
import { isMobile }                     from 'react-device-detect';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

// import { Button, ButtonToolbar, Navbar, Nav, NavItem, Container, Image, Card, Col } from 'react-bootstrap';
import { Button, 
	Navbar, 
	Image, 
	Card, Col, 
	Table } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../my.css';

const styles = {
	st_title : {
		fontWeight: 'bold'
	},
	st_title0 : {
		fontWeight: 'bold',
		fontSize: '26px'
	},
	st_btn: {
		width: '80%'
	},
	st_logo: {
		width: '85px'
	},
	card_01: {
		backgroundColor: 'rgba( 0, 0, 0, 0.0 )',
		border: 'none'
	}
};

function Top() {
	console.log( "Top start" );
	console.log( "top member_link_key:" + global.member_link_key );
	const history = useHistory();

	const [ todoList, setToDoList ] = useState([]);

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			const cnsRes = await axios.post(  Config().restful.host_name + '/api/event/gets', {
				member_link_key : global.member_link_key
			});
			setToDoList( cnsRes.data.result );
		}
		getsVal( global.member_link_key );
	}, []);

	function onClikeVital( _target ){
		history.push( _target );
	}
	function onClickEvent( _event_no ){
		history.push( '/eventview', {
			event_no : _event_no
		});
	}

	return (
		<div>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm >&emsp;&emsp;<img style={styles.st_logo} src="img/logo.png" /></Col>
					<Col sm >
						{ global.face_img !== '' && ( <Image src={global.face_img} style={{width:75}} className="roundedCircle" /> )}
						&emsp;<span style={styles.st_title0}>{global.name}様</span>
					</Col>
				</Navbar>
			</header>
			<main>
				<br />
				<br />
				<Card style={styles.card_01}>
					<div className="text-center row">
					<IconContext.Provider value={{ size:'120px'}}>
						<div className="col-sm-6 col-12">
							{isMobile ? (
								<Navbar.Brand onClick={() => onClikeVital('/vital')}> <Button  variant="link"><img style={styles.st_btn} src="img/btn_m_vital.png" /></Button> </Navbar.Brand>
							) : (
								<Navbar.Brand onClick={() => onClikeVital('/vital')}> <Button  variant="link"><img style={styles.st_btn} src="img/btn_vital.png" /></Button> </Navbar.Brand>
							)}
						</div>
						<div className="col-sm-6 col-12">
							{isMobile ? (
								<Navbar.Brand onClick={() => onClikeVital('/mypage')}> <Button variant="link"><img style={styles.st_btn} src="img/btn_m_mypage.png" /></Button> </Navbar.Brand>
							) : (
								<Navbar.Brand onClick={() => onClikeVital('/mypage')}> <Button variant="link"><img style={styles.st_btn} src="img/btn_mypage.png" /></Button> </Navbar.Brand>
							)}
						</div>
					</IconContext.Provider>
					</div>
				</Card>
				<br />
				<br />
				<Card style={styles.card_01}>
					<Card.Body>
						<Table striped bordered hover style={{backgroundColor:'white'}}>
							<thead>
								<tr className="text-center h3" style={{ backgroundColor:'#fff33d' }}>
									<th colspan="4">お知らせ</th>
								</tr>
								<tr className="text-center h6" >
									<th></th>
									<th>イベント<br />開催場所</th>
									<th>開催日<br />受付終了</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{todoList.map(( item ) =>
									<tr className="text-center h5">
										<td>
										{ item.status === 0 && ( <span style={{color:'#008800'}}>受付中</span>)}
										{ item.status === 1 && ( <span style={{color:'#ff0000'}}>満員御礼</span>)}
										{ item.status === 2 && ( <span style={{color:'#808080'}}>受付終了</span>)}
										<br />
										{ item.count > 0 && ( <span style={{color:'#ff0000'}}>申込済</span>)}
										</td>
										<td>{item.name}<br />{item.place_name}</td>
										<td>{item.start_ymd}<br />{item.reception_end_ymd}</td>
										<td>
											<button className="btn btn-primary btn-sm" onClick={ () => onClickEvent( item.event_no )}><span style={styles.st_title}>表示</span></button>
										</td>
									</tr>
								)}	
							</tbody>
						</Table>
					</Card.Body>
				</Card>
				<br />
				<br />
				<br />
				<br />
				<br />
			</main>
			<footer>
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</div>
	);
}

export default Top;
