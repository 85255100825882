import Config               from '../config/config';

import React, { useRef, useCallback, useState, useEffect  }  from 'react';
import axios                from 'axios';
import Webcam               from 'react-webcam';
import { useHistory  } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap'; import { IconContext }                  from "react-icons";
import { Navbar, Card, Col } from 'react-bootstrap'; import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyCamera() {
console.log( "MyBank start" );
console.log( "MyBank member_link_key:" + global.member_link_key );

	// https://www.npmjs.com/package/react-webcam
	const videoConstraints = {
		width      : 360,
		height     : 360,
		facingMode : "user"
	};

	const history = useHistory();
	const [ inVal, setInVal ] = useState('');

	let wFaceImg = '';
	const updateVal = async() => {
		try {
			const response = await axios.post( Config().restful.host_name + '/api/member/mycamera/upd', {
				member_link_key : global.member_link_key,
				data            : wFaceImg
			});
			if( response.data.result === 'OK' ) {
				console.log( "Update OK" );
				alert( "更新しました" );
				history.push( '/top' );
			} else {
				console.log( "Vital Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	function onClikeVital( _target ){
		history.push( _target );
	}

	const webcamRef = React.useRef(null);
	const capture = React.useCallback(
		() => {
			const imageSrc = webcamRef.current.getScreenshot();
			alert( "撮影しました" );

			setInVal( imageSrc );
			localStorage.setItem( "face_img", imageSrc );
			global.face_img = imageSrc;
			wFaceImg = imageSrc;
			updateVal();
//			const imgBuf = Buffer.from( imageSrc.replace("data:image/jpeg;base64,", ""),
//				"base64"
//			)
		},
		[webcamRef]
	);

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg">
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h2">お顔の写真撮影</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<Card>
					<Card.Body>
						<div>
							<Webcam
								audio={false}
								height={720}
								ref={webcamRef}
								screenshotFormat="image/jpeg"
								width={720}
								videoConstraints={videoConstraints}
							/>
							<button onClick={capture}>　撮　影　</button>
						</div>
						<br />
						<br />
						<div>
							<img style={{width:360}} src={inVal} alt="" />
						</div>
						<br />
						<br />
					</Card.Body>
				</Card>
		    </main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyCamera;

