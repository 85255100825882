import Config               from '../config/config';

import React, { useState, useEffect }  from 'react';
import axios                from 'axios';
import { useHistory, useLocation  } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";
import Iframe from 'react-iframe';

import 'bootstrap/dist/css/bootstrap.min.css';

function EventView() {
	console.log( "EventView start" );
	console.log( "EventView member_link_key:" + global.member_link_key );

	const history = useHistory();
	const [ inVal, setInVal ] = useState({
		member_link_key : global.member_link_key,
		event_no        : "",
		name            : "",
		start_ymd       : "",
		end_ymd         : "",
		fee             : "0",
		part_number     : "0",
		event_url       : "",
		count           : "0"
	});
//	const [ todoList, setToDoList ] = useState([]);
	const cnsLocation = useLocation();
	const cnsEventNo = cnsLocation.state.event_no;

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			const cnsRes = await axios.post( Config().restful.host_name + '/api/event/get', {
				member_link_key : global.member_link_key,
				event_no        : cnsEventNo
			} )
			setInVal( cnsRes.data.result );
		}
		getsVal( global.member_link_key );
	}, []);

//	const handleChange = (event) => {
//		setInVal({ ...inVal, [event.target.name]: event.target.value });
//	}

	function onClikeVital( _target ){
		history.push( _target );
	}

	const updateVal = async() => {
		try {
			const response = await axios.post( Config().restful.host_name + '/api/member/stripe/event_charge', {
				member_link_key : global.member_link_key,
				data : inVal
			});
			if( response.data.result === 'OK' ) {
				console.log( "Update OK" );
				alert( "更新しました" );
				history.push( '/top' );
			} else {
				console.log( "Vital Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">イベント情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<div className="row col-12">
					<div className="row col-6">
						<label class="text-center h3">{inVal.name}</label><br />
						<label class="text-bold h4">&ensp;開催日　　：{inVal.start_ymd}</label>
						<label class="text-bold h4">&ensp;費用　　　：{inVal.fee}</label>
						<label class="text-bold h4">&ensp;参加人数　：{inVal.part_number}</label>
					</div>
					<div className="col-6">
						{ inVal.count === 0 && ( <button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　参加決済　&emsp;</button> )}
						{ inVal.count > 0 && ( <span class="h4" style={{color:'#ff0000'}}>応募済みです</span> )}
					</div>
				</div>
				<Iframe id = 'page1'
                    url      = {inVal.event_url}
                    position ='absolute'
                    width    ='80%'
                    height   ='90%'/>
		    </main>
			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default EventView;

