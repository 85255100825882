import Config               from '../config/config';

import React, { useState }  from 'react';
import axios                from 'axios';
import { useHistory, Link } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, NavItem, Container, Image, Card, Col } from 'react-bootstrap';
import { Navbar, Container, Card, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../sty.css';

function NewUser() {
console.log( "NewUserstart" );

	const [ inVal, setInVal ] = useState({
		login_name : "",
		mail       : ""
	});

	const history = useHistory();

	const updateVal = async() => {
		try {
			const response = await axios.post(  Config().restful.host_name + '/api/member/newuser', inVal );
			console.dir( response );
			if( response.data.result === 'OK' ) {
				alert( 'ログインIDが作成されました。\n指定のメールアドレスにログイン情報を送信しました。\nログインしてください。' );
				history.push( '/' );
			} else {
				alert( '指定のログインIDは使用されています。' );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	return (
		<div>
			<header>
				{ /* <Navbar bg="light" expand="lg" >  */ }
				{ /* <Navbar class="bg_pono" expand="lg" > */ }
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">&ensp;ポノクラブ </Col>
					<Col sm>&emsp;</Col>
					<Col sm > </Col>
				</Navbar>
			</header>
			<main>
				<br />
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Header className='text-center h4'>新規登録 </Card.Header>
					<Card.Body>
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">ご希望会員ID</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text"
									name="login_name"
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">メールアドレス</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="mail"
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　登録開始　&emsp;</button>
							</div>
						</div>
						<br />
						<br />
						<div className="row col-12 text-center">
							<label class="text-danger h5">※パスワードは"passwd"と設定されます。ログインして変更してください。</label>
						</div>
						<br />
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<Link to={`/`} className="h4">ログイン画面へ戻ります</Link>
							</div>
						</div>
					</Card.Body>
				</Card>
				<br />
				<br />
				<br />
				<br />
			</main>
			<footer>
				<Navbar fixed="bottom" bg="light" expand="lg">
					<Container>
					</Container>
				</Navbar>
			</footer>
		</div>
	);
}

export default NewUser;
