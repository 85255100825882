import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                            from 'axios';
import { useHistory  }                  from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col, Table } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyMedicine() {
	console.log( "MyMedicinestart" );
	const history = useHistory();

//	const [ inVal, setInVal ] = useState({
//		member_link_key  : global.member_link_key,
//		name             : "",
//		usage            : "",
//	});
	const [ todoList, setToDoList ] = useState([]);

//	const handleChange = (event) => {
//		setInVal({ ...inVal, [event.target.name]: event.target.value });
//	}

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			const cnsRes = await axios.post(  Config().restful.host_name + '/api/member/mymedicine/gets', { member_link_key: _member_link_key })
			setToDoList( cnsRes.data.result );
		}
		getsVal( global.member_link_key );
	}, []);

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">お薬リスト</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<br />
				<Card>
					<Card.Body>
						<Table striped bordered hover>
							<thead>
								<tr className="text-center"> <th>薬の名称<br />用法</th></tr>
							</thead>
							<tbody>
								{todoList.map(( item ) =>
									<tr className="text-center">
										<td>{item.name}<br />{item.usage}</td>
									</tr>
								)}	
							</tbody>
						</Table>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={() => onClikeVital('/mymedicineadd')}>&emsp;　追　加　&emsp;</button>
							</div>
						</div>
					</Card.Body>
				</Card>
		    </main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyMedicine;


