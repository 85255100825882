import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                from 'axios';
// import { useAlert }         from 'react-alert';
import { useHistory  } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyEmergency() {
	console.log( "Emergency start" );
	console.log( "Emergency member_link_key:" + global.member_link_key );
	const history = useHistory();
	const [ inVal, setInVal ] = useState({ });

	const updateVal = async() => {
		try {
			const response = await axios.post(  Config().restful.host_name + '/api/member/emergency/put', {
				member_link_key : global.member_link_key,
				data            : inVal
			});
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "Editor OK" );
				alert( "編集しました" );
				history.push( '/top' );
			} else {
				console.log( "MyEmergency Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			const cnsRes = await axios.post(  Config().restful.host_name + '/api/member/emergency/get', { member_link_key: _member_link_key })
			if( cnsRes.data.stat == 'OK' ) {
				setInVal( cnsRes.data.result );
			}
		}
		getsVal( global.member_link_key );
	}, []);

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h2">緊急連絡先情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

    		<main>
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Body>
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">氏名</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="name"
									value={inVal.name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">かな氏名</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="k_name"
									value={inVal.k_name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">続柄</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="relation"
									value={inVal.relation}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-sm-4 h3 text-right">電話番号</div>
							<div className="row col-12 col-sm-8 h3">
								<input
									className="col-sm-3 col-4 textlines"
									type="text" 
									name="tel01"
									value={inVal.tel01}
									onChange={handleChange}
								/>
								<input
									className="col-sm-3 col-4 textlines"
									type="text" 
									name="tel02"
									value={inVal.tel02}
									onChange={handleChange}
								/>
								<input
									className="col-sm-3 col-4 textlines"
									type="text" 
									name="tel03"
									value={inVal.tel03}
									onChange={handleChange}
								/>
							</div>
						</div>

						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　更　新　&emsp;</button>
							</div>
						</div>
						<br />
						<br />
						<br />
					</Card.Body>
				</Card>
	    	</main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyEmergency;

