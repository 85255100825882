import Config               from '../config/config';

// import React, { useState, useEffect  }  from 'react';
import React, { useState }  from 'react';
import axios                from 'axios';
// import { useAlert }         from 'react-alert';
import { useHistory  } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyMedicineAdd() {
	console.log( "MyMedicinAdd start" );
	console.log( "MyMedicinAdd member_link_key:" + global.member_link_key );
	const history = useHistory();
	const [ inVal, setInVal ] = useState({
		link_key        : "",
		name            : "",
		usage           : "",
	});

	const updateVal = async() => {
		try {
			const response = await axios.post(  Config().restful.host_name + '/api/member/mymedicine/put', {
				member_link_key : global.member_link_key,
				data            : inVal
			});
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "Editor OK" );
				history.goBack();
			} else {
				console.log( "MyEmergencyEdit Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">お薬情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

    		<main>
				<br />
				<Card>
					<Card.Body>
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">名称</div>
							<div className="col-sm-8 h3">
								<input
									className="col-sm-12"
									type="text" 
									name="name"
									value={inVal.name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">用法</div>
							<div className="col-sm-8 h3">
								<input
									className="col-sm-12"
									type="text" 
									name="usage"
									value={inVal.usage}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />

						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　追　加　&emsp;</button>
							</div>
						</div>
					</Card.Body>
				</Card>
	    	</main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyMedicineAdd;

