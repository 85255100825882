import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                from 'axios';
import { useAlert }         from 'react-alert';
import { useHistory  }      from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table } from 'react-bootstrap';
import { Navbar, Card, Col, Table } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function Vital() {
console.log( "Vital start" );
console.log( "vital member_link_key:" + global.member_link_key );
	const history = useHistory();
	const [ inVal, setInVal ] = useState({
		member_link_key  : global.member_link_key,
		measurement_ymd  : "",
		measurement_time : "",
		temperature      : "",
		pulse            : "",
		blood_pressure   : ""
	});
	console.dir( inVal );
	const [ todoList, setToDoList ] = useState([]);

	const alert = useAlert();

	const updateVal = async() => {
		try {
			const response = await axios.post(  Config().restful.host_name + '/api/member/vital/put', inVal );
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "Update OK" );
				alert( "更新しました" );
				history.push( '/vital' );
			} else {
				console.log( "Vital Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	const healthStat = async() => {
		try {
			const response = await axios.post(  Config().restful.host_name + '/api/member/vital/put_health', inVal );
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "Update OK" );
				alert( "元気ですを更新しました" );
				history.push( '/vital' );
			} else {
				console.log( "Vital Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			const cnsRes = await axios.post(  Config().restful.host_name + '/api/member/vital/gets', { member_link_key: _member_link_key })
			setToDoList( cnsRes.data.result );
		}
		getsVal( global.member_link_key );
	}, []);

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<div>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">健康管理情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>
			<main>
				<Card>
					<Card.Body>
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-warning btn-lg" onClick={() => healthStat()}>&emsp;　元気です！　&emsp;</button>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">計測日</div>
							<div className="col-sm-6 h3">
								<input
									className="col-sm-7 textlines"
									type="date" 
									name="measurement_ymd"
									value={inVal.measurement_ymd}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">計測時間</div>
							<div className="col-sm-6 h3">
								<input
									className="col-sm-4 textlines"
									type="time" 
									name="measurement_time"
									value={inVal.measurement_time}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">体　温</div>
							<div className="col-sm-6 h3">
								<input
									className="col-sm-6 textlines"
									type="number" 
									name="temperature"
									value={inVal.temperature}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">脈　拍</div>
							<div className="col-sm-6 h3">
								<input
									className="col-sm-6 textlines"
									type="number" 
									name="pulse"
									value={inVal.pulse}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">血　圧</div>
							<div className="col-sm-6 h3">
								<input
									className="col-sm-6 textlines"
									type="number" 
									name="blood_pressure"
									value={inVal.blood_pressure}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　更　新　&emsp;</button>
							</div>
						</div>
					</Card.Body>
					<Card.Body>
						<Table striped bordered hover  style={{ backgroundColor:'white' }}>
							<thead class="text-center h5" >
								<tr><th>計測日付</th><th>体温</th><th>脈拍</th><th>心拍数</th></tr>
							</thead>
							<tbody class="h5">
								{todoList.map(( item ) =>
									<tr className="text-center">
										<td>{item.measurement_ymd}</td>
										<td>{item.temperature}</td>
										<td>{item.pulse}</td>
										<td>{item.blood_pressure}</td>
									</tr>
								)}	
							</tbody>
						</Table>
						<br />
						<br />
						<br />
						<br />
					</Card.Body>
				</Card>
			</main>
			<footer>
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</div>
	);
}

export default Vital;
