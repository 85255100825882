// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal } from 'react-bootstrap';
import { Button, Navbar, Card, Col, Table, Modal } from 'react-bootstrap';
import React, { useState }        from 'react';
import { useHistory  }            from 'react-router-dom';
import { IconContext }            from "react-icons";
import { FaHome }                 from "react-icons/fa";
import { FaHeartbeat }            from "react-icons/fa";
import { FaInfoCircle }           from "react-icons/fa";

import { ImUser }                 from "react-icons/im";
import { FaPhoneAlt }             from "react-icons/fa";
import { FaCameraRetro }          from "react-icons/fa";
import { AiFillMail }             from "react-icons/ai";
import { SiEnpass }               from "react-icons/si";
import { FaAddressCard }          from "react-icons/fa";
import { RiBankFill }             from "react-icons/ri";
import { RiBankCard2Fill }        from "react-icons/ri";
import { RiHospitalFill }         from "react-icons/ri";
import { RiMedicineBottleFill }   from "react-icons/ri";
import { RiGroupFill }            from "react-icons/ri";
import { RiCalendarEventFill }    from "react-icons/ri";
import { FaHandshake }            from "react-icons/fa";
import { FaUserShield }           from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyPage( props ) {
	const styles = {
		st_title : {
			fontWeight: 'bold'
		},
		st_title0 : {
			fontWeight: 'bold',
			fontSize: '20px'
		},
		st_title1 : {
			fontWeight: 'bold',
			fontSize: '20px'
		}
	};
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const history = useHistory();

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<div>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg">
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">マイページ</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<Card  style={{ backgroundColor: 'rgba(255,255,255,0.0)' }}>
					<Card.Body>
						<IconContext.Provider value={{ size:'50px'}}>
							<div className="row col-12" style={{ marginBottom: '10px' }}>
								<div className="col-sm-6" style={{ paddingBottom: '10px' }}><button type="button" onClick={() => onClikeVital('/account')}><ImUser /></button>&emsp;<span style={styles.st_title1}>アカウント情報</span></div>
								<div className="col-sm-6"><button type="button" onClick={() => onClikeVital('/myaddress')}><FaAddressCard /></button>&emsp;<span style={styles.st_title1}>現住所・電話番号</span></div>
							</div>
							<div className="row col-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
								<div className="col-sm-6" style={{ paddingBottom: '10px' }}><button type="button" onClick={() => onClikeVital('/mycamera')}><FaCameraRetro /></button>&emsp;<span style={styles.st_title1}>顔写真を撮影する</span></div>
								<div className="col-sm-6"><button type="button" onClick={() => onClikeVital('/mymail')}><AiFillMail /></button>&emsp;<span style={styles.st_title1}>メールアドレス</span></div>
							</div>
							<div className="row col-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
								<div className="col-sm-6" style={{ paddingBottom: '10px' }}><button type="button" onClick={() => onClikeVital('/myemergency')}><FaPhoneAlt /></button>&emsp;<span style={styles.st_title1}>緊急連絡先情報</span></div>
								<div className="col-sm-6" ><button type="button" onClick={() => onClikeVital('/mypasswd')}><SiEnpass /></button>&emsp;<span style={styles.st_title1}>パスワード変更</span></div>
							</div>
							<div className="row col-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
								<div className="col-sm-6" style={{ paddingBottom: '10px' }}><button type="button" onClick={() => onClikeVital('/mybank')}><RiBankFill /></button>&emsp;<span style={styles.st_title1}>取引銀行</span></div>
								<div className="col-sm-6"><button type="button" onClick={() => onClikeVital('/mycard')}><RiBankCard2Fill /></button>&emsp;<span style={styles.st_title1}>カード情報</span></div>
							</div>
							<div className="row col-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
								<div className="col-sm-6" style={{ paddingBottom: '10px' }}><button type="button" onClick={() => onClikeVital('/mydoc')}><RiHospitalFill /></button>&emsp;<span style={styles.st_title1}>かかりつけ情報</span></div>
								<div className="col-sm-6"><button type="button" onClick={() => onClikeVital('/mymedicine')}><RiMedicineBottleFill /></button>&emsp;<span style={styles.st_title1}>お薬リスト</span></div>
							</div>
							<div className="row col-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
								<div className="col-sm-6" style={{ paddingBottom: '10px' }}><button type="button" onClick={() => onClikeVital('/myfee')}><RiGroupFill /></button>&emsp;<span style={styles.st_title1}>会費登録</span></div>
								<div className="col-sm-6"><button type="button" onClick={() => onClikeVital('/myhistory')}><RiCalendarEventFill /></button>&emsp;<span style={styles.st_title1}>イベント参加履歴</span></div>
							</div>
							<div className="row col-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
								<div className="col-sm-6" style={{ paddingBottom: '10px' }}><button type="button" onClick={() => onClikeVital('/terms')}><FaHandshake /></button>&emsp;<span style={styles.st_title1}>利用規約</span></div>
								<div className="col-sm-6"><button type="button" onClick={() => onClikeVital('/privacy')}><FaUserShield /></button>&emsp;<span style={styles.st_title1}>プライバシーポリシー</span></div>
							</div>
							<div className="row col-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
								<div className="col-sm-6" style={{ paddingBottom: '10px' }}><Button variant="primary" onClick={handleShow}><span style={styles.st_title}>ログアウト</span></Button></div>
							</div>
						</IconContext.Provider>
					</Card.Body>
					<br />
					<br />
					<br />
					<br />
				</Card>
		    </main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>ログアウト</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				アプリケーションからログアウトしますか？
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>キャンセル </Button>
					<Button variant="primary"   onClick={() => onClikeVital('/')}>ログアウトします</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default MyPage;
