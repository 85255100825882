import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                from 'axios';
// import { useAlert }         from 'react-alert';
import { useHistory  } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyMedicineEdit() {
	console.log( "MyMedicinEdit start" );
	console.log( "MyMedicinEdit member_link_key:" + global.member_link_key );
	const history = useHistory();
	const [ inVal, setInVal ] = useState({
		member_link_key : global.member_link_key,
		name            : "",
		usage           : "",
	});
	const [ todoList, setToDoList ] = useState([]);

	const updateVal = async() => {
		try {
			const response = await axios.post(  Config().restful.host_name + '/api/member/mymedicinedit/put', inVal );
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "Editor OK" );
				alert( "編集しました" );
				history.push( '/mymedicine' );
			} else {
				console.log( "MyEmergencyEdit Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	useEffect(() => {
		const getsVal = async( _link_key ) => {
			console.log( "getsVal start" );
			const cnsRes = await axios.post(  Config().restful.host_name + '/api/member/emergencyedit/get', { link_key: _link_key })
			setToDoList( cnsRes.data.result );
		}
		getsVal( global.member_link_key );
	}, []);

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar bg="light" expand="lg">
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">お薬情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

    		<main>
				<br />
				<Card>
					<Card.Body>
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">名称</div>
							<div className="col-sm-8 h3">
								<input
									className="col-sm-12"
									type="text" 
									name="name"
									value={inVal.name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">用法</div>
							<div className="col-sm-8 h3">
								<input
									className="col-sm-12"
									type="text" 
									name="k_name"
									value={inVal.k_name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />

						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　更　新　&emsp;</button>
							</div>
						</div>
					</Card.Body>
				</Card>
	    	</main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} expand="lg">
					<IconContext.Provider value={{ size:'40px'}}>
						<Col sm onClick={() => onClikeVital('/top')}>&ensp;<FaHome />Top</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyMedicineEdit;

