const Config = () => {
	if( process.env.REACT_APP_PONO_ENV === 'development' ) {
		return{
			restful : {
				host_name : 'http://localhost:3001'
			}
		}
	} else {
		return{
			restful : {
				host_name : 'https://bkend.karin-pono.com'
			}
		}
	}
};
export default Config;

