import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                            from 'axios';
import { useHistory  } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyDoc() {
	console.log( "MyDoc start" );

	const history = useHistory();
	const [ inVal, setInVal ] = useState({
		hospital_name   : "",
		name            : "",
		card_number     : "",
		zip01           : "",
		zip02           : "",
		zip03           : "",
		pref            : "",
		addr0           : "",
		addr1           : "",
		building        : "",
		tel01           : "",
		tel02           : "",
		tel03           : "",
	});

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	const complementAddress = () => {
		const { AjaxZip3 } = window;
		AjaxZip3.zip2addr(
			'zip01',
			'zip02',
			'pref',
			'addr0',
			'addr1',
		);
	};

	const onBlurZipcode = () => {
		setInVal({
			...inVal,
			pref  : document.getElementById('pref').value,
			addr0 : document.getElementById('addr0').value,
			addr1 : document.getElementById('addr1').value
		});
	};

	const updateVal = async() => {
		try {
			const response = await axios.post(  Config().restful.host_name + '/api/member/mydoc/upd', {
				member_link_key : global.member_link_key,
				data : inVal
			});
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "Update OK" );
				alert( "更新しました" );
				history.push( '/mypage' );
			} else {
				console.log( "MyAddress Update NG" );
				alert( "更新エラー" );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			console.log( "member_link_key:" + _member_link_key );
			const cnsRes = await axios.post(  Config().restful.host_name + '/api/member/mydoc/get', { member_link_key: _member_link_key })
			setInVal( cnsRes.data.result );
		}
		getsVal( global.member_link_key );
	}, []);

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">かかりつけ情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Body>
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">病院名</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="hospital_name"
									value={inVal.hospital_name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">担当医</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="name"
									value={inVal.name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">診察券番号</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="card_number"
									value={inVal.card_number}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">郵便番号</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-4 col-sm-4 textlines"
									type="text" 
									name="zip01"
									value={inVal.zip01}
									onChange={handleChange}
								/>
								-
								<input
									className="col-4 col-sm-4 textlines"
									type="text" 
									name="zip02"
									value={inVal.zip02}
									onChange={handleChange}
									onKeyUp={complementAddress}
									onBlur={onBlurZipcode}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">都道府県</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="pref"
									id="pref"
									value={inVal.pref}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">市区町村</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="addr0"
									id="addr0"
									value={inVal.addr0}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">番地</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="addr1"
									id="addr1"
									value={inVal.addr1}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">建物名</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="building"
									value={inVal.building}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">電話番号</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-3 col-sm-3 textlines"
									type="text" 
									name="tel01"
									value={inVal.tel01}
									onChange={handleChange}
								/>
								-
								<input
									className="col-3 col-sm-3 textlines"
									type="text" 
									name="tel02"
									value={inVal.tel02}
									onChange={handleChange}
								/>
								-
								<input
									className="col-3 col-sm-3 textlines"
									type="text" 
									name="tel03"
									value={inVal.tel03}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　更　新　&emsp;</button>
							</div>
						</div>
						<br />
						<br />
						<br />
						<br />
						<br />
					</Card.Body>
				</Card>
		    </main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyDoc;

