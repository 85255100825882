import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                from 'axios';
import { useHistory  } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyBank() {
console.log( "MyBank start" );
console.log( "MyBank member_link_key:" + global.member_link_key );

	const history = useHistory();
	const [ inVal, setInVal ] = useState({
		name            : "",
		branch_name     : "",
		type            : "0",
		account_number  : "",
		account_name    : ""
	});

	const updateVal = async() => {
		try {
			const response = await axios.post( Config().restful.host_name + '/api/member/mybank/upd', {
				member_link_key : global.member_link_key,
				data            : inVal
			});
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "Update OK" );
				alert( "更新しました" );
				history.push( '/mypage' );
			} else {
				console.log( "MyAddress Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			const cnsRes = await axios.post( Config().restful.host_name + '/api/member/mybank/get', { member_link_key: _member_link_key })
			if( cnsRes.data.stat == 'OK' ) {
				setInVal( cnsRes.data.result );
			}
		}
		getsVal( global.member_link_key );
	}, []);

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg">
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h2">取引銀行情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Body>
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">銀行名</div>
							<div className="col-sm-8 h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="name"
									value={inVal.name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">支店名</div>
							<div className="col-sm-8 h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="branch_name"
									value={inVal.branch_name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">口座種別</div>
							<div className="col-sm-8 h3">
								<div className="form-check form-check-inline">
									<input className="form-check-input" type="radio" name="type" id="typea" value="0"
										checked={inVal.type == '0'}
										onChange={handleChange}
									/>
									<label className="form-check-label" for="radio2a">普通</label>
								</div>
								<div className="form-check form-check-inline">
									<input className="form-check-input" type="radio" name="type" id="typeb" value="1"
										checked={inVal.type == '1'}
										onChange={handleChange}
									/>
									<label className="form-check-label" for="radio2b">当座</label>
								</div>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">口座番号</div>
							<div className="col-sm-8 h3">
								<input
									className="col-sm-6 textlines"
									type="text" 
									name="account_number"
									value={inVal.account_number}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">口座名</div>
							<div className="col-sm-8 h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="account_name"
									value={inVal.account_name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={() => updateVal()}>&emsp;　更　新　&emsp;</button>
							</div>
						</div>
						<br />
						<br />
						<br />
						<br />
						<br />
					</Card.Body>
				</Card>
		    </main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyBank;

