import Config               from '../config/config';

import React, { useState, useEffect  } from 'react';
import axios                           from 'axios';
import { useAlert }                    from 'react-alert';
import { useHistory, Link }            from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, NavItem, Container, Image, Card, Col } from 'react-bootstrap';
import { ButtonToolbar, Navbar, Container, Card, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../sty.css';

function Login() {
console.log( "Login start" );
	const [ inVal, setInVal ] = useState({
		passwd     : "",
		login_name : ""
	});

	const alert = useAlert();
	const history = useHistory();

	const checkLogin = async() => {
		try {
			const response = await axios.post( Config().restful.host_name + '/api/member/login/complete', inVal );
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "login OK" );
				console.dir( response.data );
				global.member_link_key = response.data.member_link_key;
				global.name            = response.data.name;
//				global.face_img        = localStorage.getItem( "face_img" );
				global.face_img        = response.data.b64_face;
				localStorage.setItem( "face_img", response.data.b64_face );
				localStorage.setItem( "login_id", inVal.login_name );
				localStorage.setItem( "passwd",   inVal.passwd );
				console.log( "login member_link_key:" + global.member_link_key );
				history.push( '/top' );
			} else {
				console.log( "login NG" );
				alert.info('ログインエラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	useEffect(() => {
		setInVal({
			login_name : localStorage.getItem("login_id"),
			passwd     : localStorage.getItem("passwd")
		});
	}, []);

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	return (
		<div>
			<header>
				{ /* <Navbar bg="light" expand="lg" >  */ }
				{ /* <Navbar class="bg_pono" expand="lg" > */ }
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h2">&ensp;ポノクラブ </Col>
					<Col sm>&emsp;</Col>
					<Col sm > </Col>
				</Navbar>
			</header>
			<main>
				<br />
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Header className='text-center h3'>ログイン </Card.Header>
					<Card.Body>
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">会員ID</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text"
									name="login_name"
									value={inVal.login_name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">パスワード</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="passwd"
									value={inVal.passwd}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={checkLogin}>&emsp;　ログイン　&emsp;</button>
							</div>
						</div>
						<br />
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<Link to={`/newuser`} className="h4">新規のお申込みはこちらから</Link>
							</div>
						</div>
					</Card.Body>
				</Card>
				<br />
				<br />
				<br />
				<br />
				<Card>
					<ButtonToolbar>
					</ButtonToolbar>
				</Card>
			</main>
			<footer>
				<Navbar fixed="bottom" bg="light" expand="lg">
					<Container>
					</Container>
				</Navbar>
			</footer>
		</div>
	);
}

export default Login;
