import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                            from 'axios';
import { useHistory  }                  from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyPasswd() {
	console.log( "MyPasswd start" );
	const history = useHistory();
	const [ inVal, setInVal ] = useState({});
	const [ inP01Val, setInP01Val ] = useState('' );

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	const updateVal = async() => {
		if( inVal.pass === inP01Val ) {
			try {
				const response = await axios.post(  Config().restful.host_name + '/api/member/mypasswd/upd', {
					member_link_key : global.member_link_key,
					data            : inVal
				});
				console.dir( response );
				if( response.data.result === 'OK' ) {
					console.log( "Update OK" );
					alert( "更新しました" );
					history.push( '/top' );
				} else {
					console.log( "Vital Update NG" );
					alert.info('更新エラー', {
						type: 'success',
						onOpen: () => {
							console.log( "open" );
						},
						onClose: () => {
							console.log( "close" );
						}
					});
					alert.remove( alert );
				}
			} catch( e ) {
				console.dir( e );
			}
		} else {
			alert( "パスワードが異なっています。もう一度入力ください。" );
		}
	}

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			console.log( "member_link_key:" + _member_link_key );
			const cnsRes = await axios.post(  Config().restful.host_name + '/api/member/mypasswd/get', { member_link_key: _member_link_key })
			setInVal( cnsRes.data.result );
		}
		getsVal( global.member_link_key );
	}, []);

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h2">パスワード変更</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Body>
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">ログインID</div>
							<div className="col-12 col-sm-8 row h3">
								<label>{inVal.login_name}</label>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">新パスワード</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="pass"
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">同じのを入力</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="pass01"
									onChange={(e) => setInP01Val(e.target.value)}
								/>
							</div>
						</div>
						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　更　新　&emsp;</button>
							</div>
						</div>
					</Card.Body>
				</Card>
		    </main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>

		</>
	);
}

export default MyPasswd;
