import Config               from '../config/config';

import React, { useMemo, useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import { useHistory  } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";
import axios                            from 'axios';
import CardSection                      from '../CardSection';

import '../sty.css';

import {
	useStripe,
	useElements,
	CardElement
} from "@stripe/react-stripe-js";

// CardElementのカスタマイズ
// https://stripe.com/docs/payments/accept-card-payments?platform=web&ui=elements

import useResponsiveFontSize from "../useResponsiveFontSize";

const useOptions = () => {
	const fontSize = useResponsiveFontSize();
	const options = useMemo(
		() => ({
			style: {
				base: {
					fontSize,
					color: "#424770",
					letterSpacing: "0.025em",
					fontFamily: "Source Code Pro, monospace",
					"::placeholder": {
						color: "#aab7c4"
					}
				},
				invalid: {
					color: "#9e2146"
				}
			}
		}),
		[fontSize]
	);

	return options;
};

const MyCardAdd02 = () => {
	const stripe   = useStripe();
	const elements = useElements();
//	const options  = useOptions();
	const history  = useHistory();

	const [errorMessage, setErrorMessage] = useState(null);

	function onClikeVital( _target ){
		history.push( _target );
	}

	const handleSubmit = async event => {
		console.log( "handleSubmit start" );
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet.
			// Stripe.jsはまだロードされていません。
			// Make sure to disable form submission until Stripe.js has loaded.
			// Stripe.jsが読み込まれるまで、フォームの送信を無効にしてください。
			return;
		}

		stripe
			.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement)
			})
			.then( function( result ) {
				console.dir( result );
				const response = axios.post( Config().restful.host_name + '/api/member/stripe/put_payment_method', {
					member_link_key   : global.member_link_key,
					payment_method_id : result.paymentMethod.id
				});
				history.push( '/mycard' );
			})
			.catch( function( err ) {
				console.log( err );
			});

//		const { cnsErr, cnsPaymentMethod }= await stripe.createPaymentMethod({
//			type: "card",
//			card: elements.getElement(CardElement)
//		});
//		console.dir( cnsPaymentMethod );
//		console.dir( cnsErr );
	};

// https://stripe.com/docs/stripe-js/appearance-api
// Element は、サイトに合わせてデザインをカスタマイズできます

	return (
		<>
			<header>
				<Navbar bg="light" expand="lg">
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">クレジットカード情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<form onSubmit={handleSubmit}>
					<label>
						<CardSection />
					</label>
					<br />
					<br />
					<button type="submit" disabled={!stripe}> カード情報登録 </button>
				</form>
		    </main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} expand="lg">
					<IconContext.Provider value={{ size:'40px'}}>
						<Col sm onClick={() => onClikeVital('/top')}>&ensp;<FaHome />Top</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
};

export default MyCardAdd02;

