import Config               from '../config/config';

import React, { useState, useEffect  }  from 'react';
import axios                            from 'axios';
import { useHistory  }                  from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function Account() {
	console.log( "Account start" );
	const history = useHistory();
	const [ inVal, setInVal ] = useState({
		member_id  : '',
		login_name : '',
		name       : '',
		k_name     : '',
		sex        : '0',
		birth_date : '',
		position   : '',
		profession : '',
		assets     : '',
		self_intro : ''
	});

	const handleChange = (event) => {
		setInVal({ ...inVal, [event.target.name]: event.target.value });
	}

	const updateVal = async() => {
		try {
			const response = await axios.post( Config().restful.host_name + '/api/member/account/upd', {
				member_link_key : global.member_link_key,
				data : inVal
			});
			if( response.data.result === 'OK' ) {
				console.log( "Update OK" );
				alert( "更新しました" );
				global.name = inVal.name;
				history.push( '/top' );
			} else {
				console.log( "Vital Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			console.log( "member_link_key:" + _member_link_key );
			const cnsRes = await axios.post( Config().restful.host_name + '/api/member/account/get', { member_link_key: _member_link_key })
			if( cnsRes.data.stat == 'OK' ) {
				setInVal( cnsRes.data.result );
			}
		}
		getsVal( global.member_link_key );
	}, []);

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h2">アカウント情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

    		<main>
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Body>
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right">会員番号</div>
							<div className="col-sm-8 h3">{inVal.member_id}</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">氏名</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="name"
									value={inVal.name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">かな氏名</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="k_name"
									value={inVal.k_name}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-sm-4 h3 text-right">性別</div>
							<div className="form-check form-check-inline col-sm-2 col-4 h3">
								<input className="form-check-input" type="radio" name="sex" id="sex0" value="0"
									checked={inVal.sex === "0"}
									onChange={handleChange}
								/>
								<label className="form-check-label" >男性</label>
							</div>
							<div className="form-check form-check-inline col-sm-2 col-4 h3">
								<input className="form-check-input" type="radio" name="sex" id="sex1" value="1"
									checked={inVal.sex === "1"}
									onChange={handleChange}
								/>
								<label className="form-check-label" >女性</label>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">生年月日</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-8 textlines"
									type="date" 
									name="birth_date"
									value={inVal.birth_date}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">役職</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="position"
									value={inVal.position}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">職業</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="profession"
									value={inVal.profession}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">資産</div>
							<div className="col-12 col-sm-8 row h3">
								<input
									className="col-sm-12 textlines"
									type="text" 
									name="assets"
									value={inVal.assets}
									onChange={handleChange}
								/>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12 col-sm-4 h3 text-right">自己紹介</div>
							<div className="col-12 col-sm-8 row h3">
								<textarea
									className="col-sm-12 textlines"
									cols="100%" rows="5"
									name="self_intro"
									value={inVal.self_intro}
									onChange={handleChange}
									>
								</textarea>
							</div>
						</div>

						<br />
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={() => updateVal()}>&emsp;　更　新　&emsp;</button>
							</div>
						</div>
						<br />
						<br />
						<br />
						<br />
						<br />
					</Card.Body>
				</Card>
	    	</main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default Account;

