// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { useHistory  } from 'react-router-dom';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function Terms() {
	const history = useHistory();

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">利用規約</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Body>
カリン株式会社（以下「当社」といいます。）が運営するウェブコンテンツをご利用いただく方（以下「利用者」といいます。）は，必ず以下の利用規約（以下「本規約」といいます。）をお読みいただいてからご利用ください。<br />
本ウェブコンテンツをご利用いただいた場合には，本規約に同意したものとみなさせていただきます。<br />
<br />
１　知的財産権<br />
本ウェブコンテンツ及びこれを構成する個別のウェブサイト，文章，写真，動画，音声その他の内容に関する著作権をはじめとする知的財産権は，特段の注記がない限り，すべて当社に帰属し又は当社が権利者からの許諾等により法令上の利用権限を有するものです。<br />
利用者は，本ウェブコンテンツを閲覧し又は本ウェブコンテンツ内で当社が明示する方法及び法令上認められた態様で利用すること可能ですが，これ以外の利用を行うことはできません。<br />
当社は，無断転載，複製，改変，編集，頒布，販売その他一切の権利侵害行為を禁止します。かかる行為が行われた場合，当事務所は，民事上又は刑事上の対応を行うことがあります。<br />
２　個人情報の取扱い<br />
当社は，本ウェブコンテンツを通じて利用者に関する個人情報の取得，利用等を行う場合，当事務所が別途制定するプライバシーポリシーの定めるところにより取扱います。<br />
３　リンク<br />
本ウェブコンテンツに付したリンク先のウェブコンテンツ及び本ウェブコンテンツへのリンクが付されているリンク元のウェブコンテンツいずれについても，当社は，その内容の真正性，適法性，有用性等について何らの保証を与えるものではありません。<br />
本ウェブコンテンツへのリンクを付することは自由ですが，この場合，法令及び公正な慣行に合致した方法により行ってください。<br />
４　免責事項<br />
当社は，本ウェブコンテンツの真正性，適法性，有用性等の確保に努めますが，それらを有することを明示的にも黙示的にも保証するものではなく，本ウェブコンテンツの利用により利用者又は第三者へ何らかの損害が生じた場合であっても，一切の責任を負いません。<br />
本ウェブコンテンツの一部又は全部は，事前の予告なく変更，公開停止又は削除されることがあります。<br />
５　お問い合わせ窓口<br />
本ウェブコンテンツに関するお問い合わせは，以下の窓口までお願いいたします。<br />
〒650-0024　神戸市中央区海岸通５丁目2-20-301<br />
電話　078-361-1540　<br />
６　本規約の改訂手続<br />
当社は，本規約を必要に応じて改訂することがあります。<br />
改訂した場合，改訂後の規約は，当事務所のウェブサイトに公開するものとし，利用者はこれに拘束されるものとします。<br />
７　準拠法及び管轄裁判所<br />
本規約及び本ウェブコンテンツに関する事項は，日本法に準拠するものとします。<br />
本規約及び本ウェブコンテンツに関連する紛争については，神戸地方裁判所を第一審の専属的合意管轄裁判所とします。<br />
<br /><br /><br />
<br /><br /><br />

					</Card.Body>
				</Card>
		    </main>

			<footer class="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default Terms;
