// import Config               from '../config/config';

// import React, { useMemo, useState, useEffect } from "react";
import React, { useState } from "react";
// import axios                                   from 'axios';
import { loadStripe }                          from "@stripe/stripe-js";
import { Elements }                            from "@stripe/react-stripe-js";
// import { useHistory  }                         from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';

import '../sty.css';
import MyCardAdd02 from './MyCardAdd02';

// 参考
// https://codesandbox.io/s/q1loc?file=/src/components/ElementDemos.js

const stripePromise = loadStripe('pk_test_51KgJ1vClyKCBTD1JEYD57RukTK9gVOt5jjYNhPC7XWjfsJaLo0iC5g7o8AbqGGW30ACGj3F4iNLHn7GaukkEa54F00OuzKCNe8');

const MyCardAdd = () => {
	console.log( "MyCardAdd start" );

	const [ inVal, setInVal ] = useState({
		member_link_key  : global.member_link_key,
		client_secret    : ""
	});

//	useEffect(() => {
//		const getsVal = async( _member_link_key ) => {
//			console.log( "getsVal start" );
//			console.log( "member_link_key:" + _member_link_key );
//			const cnsRes = await axios.post( 'http://localhost:3001/api/member/stripe/get_client_secret', { member_link_key: _member_link_key })
//			setInVal( cnsRes.data.result );
//		}
//		getsVal( global.member_link_key );
//
//	}, []);

	const options = {
		// passing the client secret obtained in step 2
		// clientSecret: inVal.client_secret,
		clientSecret: global.client_secret
		// Fully customizable with appearance API.
		//appearance: {/*...*/},
	};
	return (
		<Elements stripe={stripePromise} options={options}>
			<MyCardAdd02 />
		</Elements>
	);

};

export default MyCardAdd;

