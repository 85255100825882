import React from 'react';
// import logo from './logo.svg';
import './App.css';

// import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card } from 'react-bootstrap';

// import Index           from './component/Index';
import Vital           from './component/Vital';
import MyPage          from './component/MyPage';
import MyMail          from './component/MyMail';
import MyPasswd        from './component/MyPasswd';
import MyAddress       from './component/MyAddress';
import MyBank          from './component/MyBank';
import MyCard          from './component/MyCard';
import MyCardAdd       from './component/MyCardAdd';
import MyDoc           from './component/MyDoc';
import MyMedicine      from './component/MyMedicine';
import MyMedicineAdd   from './component/MyMedicineAdd';
import MyMedicineEdit  from './component/MyMedicineEdit';
import MyFee           from './component/MyFee';
import MyHistory       from './component/MyHistory';
import MyEmergency     from './component/MyEmergency';
import NotFound        from './component/NotFound';
import Account         from './component/Account';
import Terms           from './component/Terms';
import Top             from './component/Top';
import Login           from './component/Login';
import Privacy         from './component/Privacy';
import NewUser         from './component/NewUser';
import EventView       from './component/EventView';
import MyCamera        from './component/MyCamera';


function App() {
console.log( "App start" );
  return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/">         <Login          /> </Route>
				<Route path="/top">            <Top            /> </Route>
				<Route path="/vital">          <Vital          /> </Route>
				<Route path="/mypage">         <MyPage         /> </Route>
				<Route path="/account">        <Account        /> </Route>
				<Route path="/mymail">         <MyMail         /> </Route>
				<Route path="/mypasswd">       <MyPasswd       /> </Route>
				<Route path="/myaddress">      <MyAddress      /> </Route>
				<Route path="/mybank">         <MyBank         /> </Route>
				<Route path="/mycard">         <MyCard         /> </Route>
				<Route path="/mycardadd">      <MyCardAdd      /> </Route>
				<Route path="/mydoc">          <MyDoc          /> </Route>
				<Route path="/mymedicine">     <MyMedicine     /> </Route>
				<Route path="/mymedicineadd">  <MyMedicineAdd  /> </Route>
				<Route path="/mymedicineedit"> <MyMedicineEdit /> </Route>
				<Route path="/myfee">          <MyFee          /> </Route>
				<Route path="/myhistory">      <MyHistory      /> </Route>
				<Route path="/myemergency">    <MyEmergency    / > </Route>
				<Route path="/terms">          <Terms          /> </Route>
				<Route path="/privacy">        <Privacy        /> </Route>
				<Route path="/newuser">        <NewUser        /> </Route>
				<Route path="/eventview">      <EventView      /> </Route>
				<Route path="/mycamera">       <MyCamera       /> </Route>
				<Route> <NotFound/> </Route>
			</Switch>
		</BrowserRouter>
  );
}

console.log( "app member_link_key:" + global.member_link_key );
export default App;
