import Config               from '../config/config';

// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col, Table } from 'react-bootstrap';
import { useHistory  }                  from 'react-router-dom';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

// import React, { useMemo, useState, useEffect } from "react";
import React, { useState, useEffect } from "react";
import axios                                   from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';

function MyFee() {
	const history = useHistory();
	const [ inVal, setInVal ] = useState({
		card_link_key   : ""
	});
	const [ inMem, setInMem ] = useState({
		member_link_key  : "",
		member_id        : "",
		next_payment_ymd : ""
	});
	const [ todoList, setToDoList ] = useState([]);

	const updateVal = async() => {
		try {
			const response = await axios.post(  Config().restful.host_name + '/api/member/myfee/upd', {
				member_link_key : global.member_link_key,
				data            : inVal
			});
			console.dir( response );
			if( response.data.result === 'OK' ) {
				console.log( "Update OK" );
				alert( "更新しました" );
				history.push( '/mypage' );
			} else {
				console.log( "MyAddress Update NG" );
				alert.info('更新エラー', {
					type: 'success',
					onOpen: () => {
						console.log( "open" );
					},
					onClose: () => {
						console.log( "close" );
					}
				});
				alert.remove( alert );
			}
		} catch( e ) {
			console.dir( e );
		}
	}

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			console.log( "member_link_key:" + _member_link_key );
			const cnsRes1 = await axios.post(  Config().restful.host_name + '/api/member/stripe/gets_payment_method', { member_link_key: _member_link_key })
			setToDoList( cnsRes1.data.data );
			setInMem( cnsRes1.data.data_mem );
			if( !inMem.next_payment_ymd ) {
				setInMem({next_payment_ymd : '決済まち'});
			}
		}
		getsVal( global.member_link_key );
	}, []);

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">会費設定</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<br />
				<Card>
					<Card.Body>
						<Table striped bordered hover>
							<thead>
								<tr class="text-center"> <th></th><th>種別</th> <th>下４桁</th> <th>有効日</th> </tr>
							</thead>
							<tbody>
								{todoList.map(( item ) =>
									<tr className="text-center">
										<td>
											<input className="form-check-input" type="radio" name="type" id="typea" value={item.link_key}
												onChange={() => setInVal({card_link_key: item.link_key })}
											/>
										</td>
										<td>{item.brand}</td>
										<td>{item.last4}</td>
										<td>{item.exp_ym}</td>
									</tr>
								)}	
							</tbody>
						</Table>
					</Card.Body>
				</Card>
				<br />
				<Card>
					<Card.Body>
						<div class="row col-12">
							<div class="text-center h5" style={{color:'blue'}}>
								カードを選択して「会費決済」をクリックしてください。
							</div>
						</div>
						<br />
						<div class="row col-12">
							<div class="col-sm-4 h3 text-right"></div>
							<div class="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={updateVal}>&emsp;　会費決済　&emsp;</button>
							</div>
						</div>
						<br />
						<div class="row col-12">
							<div class="text-center h5" style={{color:'red'}}>
								初回は翌月２０日に翌々月分の会費が決済されます<br />
								例）４月１５日お申し込みは、５月２０日に６月分会費が決済されます。<br />
								以降は、毎月２０日決済されます。<br />
								クレジットカードで引き落としがスタートするまではトライアル期間と表示されます。
							</div>
						</div>
					</Card.Body>
				</Card>
				<br />
				<Card>
					<Card.Body>
						<div class="row col-12">
							<div class="text-center h5" >
								前回決済日：{ inMem.next_payment_ymd }
							</div>
						</div>
					</Card.Body>
				</Card>
		    </main>

			<footer class="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyFee;
