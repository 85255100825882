/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import './CardSectionStyles.css'

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
//			color: "#32325d",
			color: "#000",
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: "antialiased",
			fontSize: "26px",
			"::placeholder": {
//				color: "#aab7c4",
				color: "#ff0000",
			},
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a",
		},
	},
};

function CardSection() {
	return (
		<label>
			&ensp;カード情報
			<CardElement options={CARD_ELEMENT_OPTIONS} />
		</label>
	);
};

export default CardSection;
