// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col } from 'react-bootstrap';
import { useHistory  } from 'react-router-dom';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function Terms() {
	const history = useHistory();

	function onClikeVital( _target ){
		history.push( _target );
	}
	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg" > 
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h2">プライバシーポリシー</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<br />
				<Card style={{ backgroundColor: 'rgba( 0, 0, 0, 0.0 )' }}>
					<Card.Body>
カリン株式会社（以下「弊社」）は、お客様、お取引先（提携先や委託先を含む）をはじめ、弊社役職員や採用応募者を含む全ての関係者（以下まとめて「お客様等」）に関する個人情報（個人情報の保護に関する法律第2条第1号に定義する「個人情報」をいう。以下同じ）について、その保護及び適正な管理を重要な社会的責務と考え、以下のプライバシーポリシーを定め、個人情報を適正に取扱います。<br />
１．法令等の遵守について<br />
弊社は、個人情報の保護に関する法律その他関連法令及びガイドライン等を遵守するとともに、本ポリシーに則り個人情報を適正に取扱うものとします。<br />
２．個人情報の取得について<br />
弊社は、業務上必要な範囲内で、適正かつ適法な手段により個人情報を取得します。<br />
３．個人情報の利用目的について<br />
弊社は、取得した個人情報を以下の利用目的の達成に必要な範囲でのみ利用し、ご本人の同意又は法令の定めなく、目的外の利用を行いません。なお、個人番号については、法令等で定められた利用目的に限り利用いたします。<br />
［利用目的］<br />
・弊社サービスに関する提供(業務の遂行)･アフターフォローを行うため<br />
・お客様等へのご連絡(お問合せや支払対応を含む)･資料等の発送･情報配信･アンケート等の実施のため<br />
・お客様等との取引管理･支払管理その他の顧客管理事務(取引時確認を含む)を行うため<br />
・弊社内部におけるマーケティング･市場調査･新サービス検討･サービス改善のため<br />
・弊社サービスに関するトラブルや不正利用の防止･再発防止のため<br />
・弊社ホームページ･サービス等の改善のため<br />
・ご本人の同意に基づき、提携先企業等に対して仲介･転送するため<br />
・弊社内の人事･労務管理（採用選考活動･役職員の健康管理･緊急連絡先としての利用を含む）及びそれらに関する諸手続のため<br />
・法令に基づく対応の他、行政機関等からの通達･指導等に対応するため<br />
・上記に附帯関連する業務を遂行するため<br />
４．個人情報の取扱いの委託について<br />
弊社は、以下の業務に関して、その業務の全部又は一部を外部に委託し、業務の遂行に必要な個人情報を業務委託先に提供することがあります。委託に際しては、再委託先への監督を含め、委託先に対する必要かつ適切な監督を行います。<br />
・弊社サービスの遂行に関して発生する業務（主に管理･バックオフィス･システム業務）<br />
・お客様等にお送りするための書類等の印刷･発送に関する業務<br />
・法律上や会計･税務上等の専門的な助言や事務処理等の提供に関する業務<br />
・弊社運営上に関する管理業務（主に帳簿類の作成･入力･申請業務）<br />
・情報システムの運用･保守に関する業務<br />
・その他、弊社業務上必要な業務<br />
５．個人情報の第三者提供について<br />
弊社は、お客様等の同意をいただいている場合や法令等に基づく場合等を除き、原則としてお客様等の個人情報を第三者に対して提供いたしません。ただし、利用目的の達成に必要な範囲内において個人情報の取扱いを委託する場合、お客様等の同意をいただくことなく、お客様等の個人情報を弊社以外の者に対して提供することがあります。なお、弊社が保有する個人情報について、ご本人からの要請があった場合は、自主的にその利用を停止します。<br />
６．個人情報の開示・訂正等<br />
弊社は、ご本人又はその代理人より、個人情報の開示、訂正等（訂正･追加･削除）、利用停止等（利用停止･消去）のご請求があった場合、住民票の写しなどの提示によりご本人確認を行った上で、弊社が適切と判断した場合には、弊社の業務に支障のない範囲内で保有する個人情報の開示等を行います。なお、ご要望にお応えできない場合は、ご本人又はその代理人に理由を説明致します。これらの具体的なご請求手続きについては、以下「１０．個人情報に関するお問い合わせ窓口」までご連絡ください。<br />
７．個人情報の安全管理措置について<br />
弊社は、個人情報の適切な収集、利用、提供、保管及び廃棄を行うとともに、これらに関する事項を定めた社内規程等を策定し、遵守します。また、個人情報の保護の重要性を常に認識し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏洩等を防止するため、個人情報を取扱う者（委託先（再委託先も含みます）の従業者も含みます）に対する教育、研修、監督等を実施するとともに、合理的な安全管理措置を講じます。安全管理措置の問題が確認された場合には、事案に応じて、速やかに是正措置を講じます。<br />
８．Webサイトにおける個人情報の取扱いについて（Cookie（クッキー）等の使用）<br />
弊社は、弊社サービスにおいて、お客様等により良いサービスを提供するため、お客様等のCookie情報等を取得し、利用する場合があります。お客様等は、ウェブブラウザの設定変更によりCookieを無効化することが可能です。但し、Cookieを無効化すると、弊社サービスの一部の機能をご利用いただけなくなる場合があります。<br />
弊社が提供するサイトでは、Google社によるアクセス解析ツール「Googlアナリティクス」を利用しています。Googleアナリティクスを利用すると、弊社発行のCookieをもとに、Google社がお客様等の弊社サイトの閲覧履歴を収集･記録･分析し、弊社は当該分析結果を受け取り、お客様等の弊社サイトのアクセス状況を把握します。Googleアナリティクスにより収集･記録･分析されたお客様等の情報には、特定の個人を識別する情報は含まれません。また、それらの情報はGoogle社により、同社プライバシーポリシー（※）に基づいて管理されます。お客様等は、ウェブブラウザの設定変更によりGoogleアナリティクスを無効化することで、お客様等の情報収集を停止することが可能です。<br />
（※）詳細は、Google社が提供する「Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用」ページ等をご参照ください。<br />
９．個人情報保護管理体制について<br />
弊社は、個人情報保護に関する管理体制等を整備し、全役職員で徹底して運用するとともに定期的な見直しを行い、継続的な改善に努めます。また、役職員が遵守すべき規則等を定め、適切な教育･研修を通じ、周知徹底を図ります。<br />
１０．個人情報に関するお問い合わせ窓口<br />
弊社は、個人情報の取扱いに関するお問い合わせ･ご質問･苦情等に適切に対応します。個人情報の取扱いに関するお問い合わせ・ご質問･苦情等は、以下の窓口にご連絡ください。<br />
[個人情報お問い合わせ窓口］<br />
電話番号　：078-361-1540（受付時間：弊社営業日の10:00～16:00）<br />
所在地等　：〒650-0024　神戸市中央区海岸通5丁目2-20-301<br />
１１．その他<br />
弊社は、個人情報に対する社会通念の変化及び技術進歩等をふまえ、個人情報保護の一層の徹底のため、本ポリシー及び個人情報の取扱いを継続的に改善するよう努めます。なお、本ポリシーを改訂した場合は、弊社ホームページ上で公開します。<br />
<br /><br /><br />
<br /><br /><br />
					</Card.Body>
				</Card>
		    </main>

			<footer class="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default Terms;
