import Config               from '../config/config';

// import { Button, ButtonToolbar, Navbar, Nav, Container, NavDropdown, Card, Row, Col, Table, Modal, Form } from 'react-bootstrap';
import { Navbar, Card, Col, Table } from 'react-bootstrap';
import { useHistory  } from 'react-router-dom';
import { IconContext }                  from "react-icons";
import { FaHome }                       from "react-icons/fa";
import { FaHeartbeat }                  from "react-icons/fa";
import { FaInfoCircle }                 from "react-icons/fa";

// import React, { useMemo, useState, useEffect } from "react";
import React, { useState, useEffect } from "react";
import axios                                   from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';

function MyCard() {
	const history = useHistory();
	const [ todoList, setToDoList ] = useState([]);

	useEffect(() => {
		const getsVal = async( _member_link_key ) => {
			console.log( "getsVal start" );
			console.log( "member_link_key:" + _member_link_key );
			const cnsRes = await axios.post( Config().restful.host_name  + '/api/member/stripe/get_client_secret', { member_link_key: _member_link_key })
			global.client_secret = cnsRes.data.client_secret;
			const cnsRes1 = await axios.post( Config().restful.host_name + '/api/member/stripe/gets_payment_method', { member_link_key: _member_link_key })
			setToDoList( cnsRes1.data.data );
		}
		getsVal( global.member_link_key );
	}, []);

	function onClikeVital( _target ){
		history.push( _target );
	}

	return (
		<>
			<header>
				<Navbar style={{ backgroundColor:'#fff33d' }} expand="lg">
					<Col sm className="h3">
						<button onClick={()=>history.goBack()}>戻る</button>
					</Col>
					<Col sm={6} className="text-center h3">カード情報</Col>
					<Col sm>&emsp;</Col>
				</Navbar>
			</header>

		    <main>
				<br />
				<Card>
					<Card.Body>
						<Table striped bordered hover>
							<thead>
								<tr className="text-center"> <th>種別</th> <th>下４桁</th> <th>有効日</th> </tr>
							</thead>
							<tbody>
								{todoList.map(( item ) =>
									<tr className="text-center">
										<td>{item.brand}</td>
										<td>{item.last4}</td>
										<td>{item.exp_ym}</td>
									</tr>
								)}	
							</tbody>
						</Table>
					</Card.Body>
				</Card>
				<br />
				<Card>
					<Card.Body>
						<div className="row col-12">
							<div className="col-sm-4 h3 text-right"></div>
							<div className="col-sm-6">
								<button className="btn btn-primary btn-lg" onClick={() => onClikeVital('/mycardadd')}>&emsp;　カード追加　&emsp;</button>
							</div>
						</div>
					</Card.Body>
				</Card>
		    </main>

			<footer className="footer">
				<Navbar fixed="bottom" style={{ backgroundColor:'#fff33d' }} className="text-center h5" expand="lg">
					<IconContext.Provider value={{ size:'60px'}}>
						<Col sm onClick={() => onClikeVital('/top')}><FaHome /><br />トップ画面</Col>
						<Col sm onClick={() => onClikeVital('/vital')}><FaHeartbeat /><br />健康管理</Col>
						<Col sm onClick={() => onClikeVital('/mypage')}><FaInfoCircle /><br />マイページ</Col>
					</IconContext.Provider>
				</Navbar>
			</footer>
		</>
	);
}

export default MyCard;


